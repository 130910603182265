<template>
	<section class='h-460 w-100p overflow-hidden  relative classroom-des'>
		<div class='absolute w-100p h-100p classroom-des-img'
			 v-lazy-bg:img='[require("@/assets/blur/classroom_wisdom.jpg"),true,require("@/assets/classroom_wisdom.jpg")]'></div>
		<div class='w-1200 classroom-content '>
			<div class='col-b-white w-500 relative'>
				<div class='col-b-blue f-s-30' style='width: 520px'>{{ classroomDetail.title }}</div>
				<div class='f-s-32   l-h-45'>{{ classroomDetail.small_title }}</div>
				<div class='f-s-14  w-500 m-t-30 l-h-24  classroom-content-detail opacity-7 '>
					·智慧教学：电子白板、创新课堂、分组教学、自动录播导播、师生交互等
				</div>
				<div class='f-s-14   w-500  l-h-24 classroom-content-detail opacity-7'>
					·智慧管理：智能教室中控、物联管控、资管管理、教学督导、考勤统计等
				</div>
				<div class='f-s-14 w-500  l-h-24 classroom-content-detail opacity-7 '>
					·智慧评价：AI学情分析、教学分析、行为分析、在线听评课、优课评选等
				</div>
			</div>
		</div>
	</section>
	<!--    方案优势-->
	<section class='text-center h-500 col-b-title white-bg content-one p-b-70'>
		<div class='p-t-80 f-s-48 content-title f-w-600'>方案优势</div>
		<div class='flex-row w-1200 text-center justify-between  classroom-item'>
			<div class='text-center classroom-item-box'>
				<img src='@/assets/plan_use.png' alt='' class='w-70 h-70 p-t-70'>
				<p class='f-s-20  f-w-500' style='margin-top: 30px'>高度融合</p>
				<p class='f-s-20  f-w-500'>一机多能，创新教学更流畅</p>
				<p class='f-s-16 m-t-16 opacity-7 l-h-26'>1台设备融合50多项信息化教学应用，为混合式线上线下教学、翻转教学、分组教学等创新教学模式提供技术支持</p>
			</div>
			<div class='text-center classroom-item-box'>
				<img src='@/assets/feature_stable@2x.png' alt='' class='w-70 h-70 p-t-70'>
				<p class='f-s-20 m-t-30 f-w-500' style='margin-top: 30px'>高度稳定 </p>
				<p class='f-s-20 m-t-20 f-w-500'>智慧教室常态化应用更顺畅</p>
				<p class='f-s-16 m-t-16 opacity-7 l-h-26'>一体化嵌入式设计稳定可靠，功耗低，大大减少多设备集成方案的故障几率，支持7*24小时不间断使用</p>
			</div>
			<div class='text-center classroom-item-box'>
				<img src='@/assets/feature_easy@2x.png' alt='' class='w-70 h-70 p-t-70'>
				<p class='f-s-20 m-t-30 f-w-500' style='margin-top: 30px'>高度易用</p>
				<p class='f-s-20 m-t-20 f-w-500'>全校信息化教学应用更简便</p>
				<p class='f-s-16 m-t-16 opacity-7 l-h-26'>平移智能手机的使用习惯，操作简单免培训，尤其适合整校一体化建设快速推广，教师养成常用习惯</p>
			</div>
		</div>
	</section>
	<!--  方案架构-->
	<section class='plan-framework'>
		<div class='plan-content w-1200 text-center col-b-title'>
			<div class='plan-title f-w-600 f-s-48 f-w-600'>方案架构</div>
			<div class='plan-small_title  col-b-blue f-w-500 f-s-22 m-t-10'>云+网+端</div>
			<div class='m-t-60 plan-show'>
				<inter-image-show :img-width='1200' :img-height='322'
								  :img-url="require('@/assets/class_plan.png')"></inter-image-show>
			</div>
		</div>
	</section>
	<!--  方案实施-->
	<section class='plan-implementation white-bg'>
		<div class='plan-content w-1200 text-center col-b-title'>
			<div class='plan-title  f-s-48 p-t-80 f-w-600'>方案实施</div>
			<div class='plan-small-title col-b-blue f-w-500 f-s-22 m-t-10 flex-space-b m-t-40'>
				<div class='framework-title l-h-32 w-100 text-center f-s-14 col-b-title pointer '
					 :class="navIndex===index?'selected':''" v-for='(item,index) in planNavList' :key='index'
					 @click='changeNav(index)'>{{ item }}
				</div>
			</div>
			<div class='plan-img'>
				<img :src='planLevelImgList[navIndex].url' alt=''>
			</div>
		</div>
	</section>

	<!--  主要应用场景-->

	<section class='plan-scene'>
		<div class='plan-content w-1200 text-center col-b-title'>
			<div class='plan-title  f-w-600 f-s-48 f-w-600'>主要应用场景</div>
			<div class='flex-space-b application-scene m-t-70'>
				<div>
					<div class='application-title f-s-32 col-b-blue f-w-500'>智慧教学，操作简单</div>
					<div class='application-box border-box p-40 brs30 white-bg '>
						<p class='m-t-0 f-s-20 f-w-500'>全信号源自由圈点、批注</p>
						<p class='opacity-7 f-w-400 w-500 f-s-16 m-t-8'>
							支持对本地电脑、外接笔记本、无线投屏、远端教室、小组屏等所有信号源自由圈点、批注，并可导出保存 </p>
						<p class='f-s-20 m-t-24 f-w-500'>一键飞屏</p>
						<p class='opacity-7 f-w-400 w-500 f-s-16 m-t-8'>教学主屏的板书、批注、资料等内容均可一键 “飞” 到任意小组屏</p>
						<p class='f-s-20 m-t-24 f-w-500'>一键广播 </p>
						<p class='opacity-7 f-w-400 w-500 f-s-16 m-t-8'>本地电脑、远端教室、外接笔记本、无线投屏等信号源画面，一键同步教室所有小组屏幕</p>
						<div class='m-t-50'>
							<inter-image-show :img-width='500' :img-height='370'
											  :img-url="require('@/assets/application.jpg')"></inter-image-show>
						</div>
					</div>
				</div>
				<div>
					<div class='application-title f-s-32 col-b-blue f-w-500'>智能中控，管控教室</div>
					<div class='application-box border-box p-40 brs30 white-bg'>
						<div class=' flex-row'>
							<div class='application-item'>
								<div class='application-item f-s-20 f-w-500'>设备控制</div>
								<div class='application-item f-w-400 l-h-24 m-t-8 f-s-16 opacity-7'>
									支持集中控制外接触控一体机、投影幕布等设备
								</div>
							</div>
							<div class='application-item m-l-40'>
								<div class='application-item f-s-20 f-w-500'>信号源 “秒速” 切换</div>
								<div class='application-item f-w-400 l-h-24 m-t-8 f-s-16 opacity-7'>
									本地电脑、远端教室、外接笔记本等信号源可秒速切换，无延迟
								</div>
							</div>
						</div>
						<div class='flex-row m-t-50'>
							<div class='application-item'>
								<div class='application-item f-s-20 f-w-500'> 物联管控</div>
								<div class='application-item f-w-400 l-h-24 m-t-8  f-s-16 opacity-7'>
									智能控制灯光、空调、窗帘等设备，打造舒适教学环境
								</div>
							</div>
							<div class='application-item m-l-40'>
								<div class='application-item f-s-20 f-w-500'>权限管理</div>
								<div class='application-item f-w-400 l-h-24 m-t-8 f-s-16 opacity-7'>
									对接学校教育资源平台，自动匹配个人数据
								</div>
							</div>
						</div>
						<div class='col-b-red f-s-12 remarks'>
							注：高级版支持物联网管控
						</div>
						<div class='m-t-40'>
							<inter-image-show :img-width='500' :img-height='370'
											  :img-url="require('@/assets/application_class.png')"></inter-image-show>
						</div>
					</div>
				</div>
			</div>
			<div class='record-video f-s-32 col-b-blue f-w-500 text-left'>智能录播，媲美人工</div>
			<div class='record-content flex p-t-36  col-b-white text-left '>
				<div style='margin-left: 40px;'>
					<p class='f-s-20'>一键录制，自由导播</p>
					<p class='f-s-16 m-t-10 w-200 opacity-7 l-h-26'>支持预约录制，支持实时预览，支持全自动或人工导播</p>
				</div>
				<div class='m-l-70'>
					<p class='f-s-20'>抗干扰能力强，跟踪准确率超过95%</p>
					<p class='f-s-16 m-t-10 l-h-26 opacity-7'>无惧镜面倒影、大屏显示变化、学生前后转身等业内长期难以克服的干扰因素</p>
					<p class='m-t-25 col-b-red  opacity-7 f-s-12'>注：仅拓展型和高级型支持精品录播</p>
				</div>
				<div class='m-l-70'>
					<p class='f-s-20'>精准采集教师走动轨迹，服务AI教学评价</p>
					<p class='f-s-16 m-t-10 opacity-7 l-h-26'>自动采集老师教室内的行动轨迹、师生互动等数据，提供更全面、准确的学情数据分析</p>
					<p class='m-t-25 col-b-red f-s-12'>注：仅高级型智慧教室支持AI学情分析</p>
				</div>
			</div>
			<div class='flex align-center teach'>
				<img src='@/assets/teach_plan.png' alt=''>
				<span class='p-l-9 f-s-32 col-b-blue f-w-500'>教学督导平台</span>
				<span class='p-l-16 f-s-20 col-b-content align-end p-t-18 f-w-400'>升级教学评价，提升教学质量</span>
			</div>
			<div class='flex justify-between m-t-25 text-left'>
				<div class='teach-item white-bg brs30'>
					<p class='f-s-20  m-t-40 m-l-40 m-r-40 f-w-500'>便捷的巡课模式</p>
					<p class='m-t-8 f-s-16 w-300 m-l-40 m-r-40 opacity-7  l-h-26'>在线实时巡课和实录巡课，可通过抓拍来记录巡视中发现的问题</p>
					<div class='m-t-100 m-b-60'>
						<inter-image-show :img-width='380' :img-height='370'
										  :img-url="require('@/assets/teach_patrol.png')"></inter-image-show>

					</div>
				</div>
				<div class='teach-item white-bg brs30 '>
					<p class='w-280 m-t-40 m-l-40 f-s-20 m-r-40 f-w-500'>量规表自定义，多维评价，促教促学</p>
					<p class='w-300 m-l-40 m-t-8 f-s-16 m-r-40 opacity-7 l-h-26'>在线听评课系统支持对教师课程、课堂学习的整体评价，提供多种打分标准</p>
					<div class=' m-b-60 m-t-100'>
						<inter-image-show :img-width='380' :img-height='370'
										  :img-url="require('@/assets/teach_gauge.png')"></inter-image-show>
					</div>
				</div>
				<div class='teach-item white-bg brs30 '>
					<p class='w-300 m-t-40 m-l-40 f-s-20 f-w-500'>AI学情分析，辅助教学改革与评估</p>
					<p class='w-300  m-l-40 m-t-8 f-s-16 opacity-7 l-h-26' style='width: 310px;'>
						自动生成教师行走热区图，采集学生专注度、师生互动等数据生成智能课堂分析报告</p>
					<div class=' teach-ai m-b-65'>
						<inter-image-show :img-width='380' :img-height='370'
										  :img-url="require('@/assets/teach_ai.png')"></inter-image-show>
						<div class='col-b-red f-s-12 m-l-40'>注：学情分析仅高级型智慧教室支持</div>
					</div>
				</div>
			</div>
			<div class='flex align-center teach '>
				<img src='@/assets/device_plan.png' alt=''>
				<span class='p-l-9 f-s-32 col-b-blue f-w-500'>设备管控平台</span>
				<span class='p-l-16 f-s-20 col-b-content align-end p-t-18 f-w-400'>便捷轻运维</span>
			</div>
			<div class='white-bg brs30 flex m-t-26 '>
				<div class='border-box p-40 text-left '>
					<div class='f-s-20 f-w-500 col-b-title'>设备智能管控</div>
					<div class='f-s-16 m-t-8 l-h-26 col-b-title opacity-7 '>1键批量升级、更新全校智慧教室终端系统，设备管控省时省力</div>
					<div class='f-s-20 m-t-24 f-w-500 col-b-title'>远程协助</div>
					<div class='f-s-16 m-t-8 l-h-26 opacity-7 col-b-title'>1秒快速响应，远程接管教师电脑、音视频远程对讲，快速解决老师问题，使用无忧</div>
					<div class='f-s-20 m-t-24 f-w-500 col-b-title'>物联控制</div>
					<div class='f-s-16 m-t-8 l-h-26 opacity-7 col-b-title'>教室教辅设备及空调、灯光、窗帘等自动控制，优化教学环境，节约能耗</div>
					<div class='f-s-12 col-b-red m-t-90'>注：“物联控制”仅高级型智慧教室支持</div>
				</div>
				<div class='m-r-20 m-50 device-plat'>
					<inter-image-show :img-width='724' :img-height='430'
									  :img-url="require('@/assets/device_plat_plan.png')"></inter-image-show>
				</div>
			</div>
			<div class='flex align-center teach'>
				<img src='@/assets/resource_plan.png' alt=''>
				<span class='p-l-9 f-s-32 col-b-blue f-w-500'>资源管理平台</span>
				<span class='p-l-16 f-s-20 col-b-content align-end p-t-18 f-w-400'>创建校本资源库，录播、直播赋能教学</span>
			</div>
			<div class='border-box p-t-40 p-l-40 p-r-40 white-bg brs30 m-t-30'>
				<div class='flex text-left flex-space-b f-w-500 f-s-20 col-b-title'>
					<div class=' width-item'>教学直播，支持内容审核，扫二维码观看，实现优质教学资源快速传播</div>
					<div class='l-h-28 width-item'>金课建设，通过建设精品课打造名校、名师、名课课堂，提升教学质量</div>
					<div class='l-h-28 width-item'>混合式教学落地，发挥线上+线下教学优势，加快教育信息化建设</div>
				</div>
				<div class='resource-img'>
					<inter-image-show :img-width='1128' :img-height='398'
									  :img-url="require('@/assets/resource_img.png')"></inter-image-show>
				</div>
			</div>
			<div class='flex align-center teach'>
				<img src='@/assets/video_plan.png' alt=''>
				<span class='p-l-9 f-s-32 col-b-blue f-w-500'>媒体发布平台</span>
				<span class='p-l-16 f-s-20 col-b-content align-end p-t-18 f-w-400'>打造校园融媒体中心</span>
			</div>
			<div class='border-box  white-bg brs30 m-t-30 '>
				<div class='flex-row f-w-500 f-s-20 col-b-title '>
					<div class='l-h-28  p-t-40 p-l-40 text-left width-item'>
						<div>一键发布，图片、文字、音视频等全媒体资源到任意多间教室</div>
						<div class='m-t-30'>支持在线直播，校园公告实时通</div>
					</div>

					<div class='video-plan m-t-24 m-l-60'>
						<inter-image-show :img-width='724' :img-height='430'
										  :img-url="require('@/assets/video_plan_img.png')"></inter-image-show>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class='data-plat m-t-70'>
		<div class='w-1200 block-center'>
			<div class='flex align-center data-plat-img  p-t-50'>
				<img src='@/assets/data_plan.png' alt='' class='w-40 h-40'>
				<span class='p-l-9 f-s-32 col-b-blue f-w-500'>数据分析平台</span>
				<span class='p-l-16 f-s-20 col-b-white align-end p-t-18 f-w-400'>AI+大数据，全面把控教学质量</span>
			</div>
			<div class='data-plat-img m-t-40'>
				<div class='col-b-white f-s-20 f-w-500 flex-row p-l-30'>
					<div class='w-300 l-h-32'>教学数据统计，如师生互动、资源统计、直播统计等</div>
					<div class='w-300 m-l-70 l-h-32'>管理数据统计，如考勤统计、巡课统计、学情分析等</div>
					<div class='m-l-70'>评课数据统计，如评课统计</div>
				</div>
			</div>
			<div class='plan-button' @click='handlePlanButtonClick'>方案参考报价</div>
		</div>
	</section>

	<el-dialog v-model='dialogTableVisible' title='方案参考报价'>
		<el-table :data='gridData'>
			<el-table-column property='index' label='序号' />
			<el-table-column property='name' label='名称' />
			<el-table-column property='type' label='型号' />
			<el-table-column property='unit' label='单位' />
			<el-table-column property='number' label='数量' />
			<el-table-column property='price' label='参考价格' />
		</el-table>
	</el-dialog>
</template>
<script>
import { defineComponent, ref } from 'vue';
import interImageShow from '@/components/interImageShow';
import { ElTable, ElTableColumn, ElDialog } from 'element-plus';
import 'element-plus/theme-chalk/base.css';
import 'element-plus/theme-chalk/el-table.css';
import 'element-plus/theme-chalk/el-table-column.css';
import 'element-plus/theme-chalk/el-dialog.css';

export default defineComponent({
	name: 'classroomUpgrade',
	components: {
		interImageShow,
		ElTable,
		ElTableColumn,
		ElDialog
	},
	setup() {
		let classroomDetail = ref({
			title: '1台核心设备+1块屏幕=1间智慧教室',
			small_title: '集成50+功能,助力1000+院校智慧校园建设'
		});
		let planNavList = ref(['高级版', '拓展版', '基础版']);
		let navIndex = ref(0);
		let planLevelImgList = ref([{ url: require('@/assets/plan_senior.png') }, { url: require('@/assets/plan_extand.png') }, { url: require('@/assets/plan_basics.png') }]);

		const dialogTableVisible = ref(false);
		const gridData = [
			{
				index: 1,
				name: '智慧教室终端',
				type: 'ESMA-8700D',
				unit: '台',
				number: 1,
				price: '200000'
			},
			{
				index: 2,
				name: '高清摄像机',
				type: 'ECM-1000',
				unit: '台',
				number: 1,
				price: '9500'
			},
			{
				index: 3,
				name: '吊装麦克风',
				type: 'EAP-1100',
				unit: '支',
				number: 1,
				price: '950'
			},
			{
				index: 4,
				name: '3D跟踪探测器',
				type: 'ETK-4711',
				unit: '台',
				number: 1,
				price: '9500'
			},
			{
				index: 5,
				name: '分组教学终端',
				type: 'EWMA-8500I',
				unit: '台',
				number: 1,
				price: '8000'
			},
			{
				index: 6,
				name: '分组教学专用AP',
				type: 'NAP-3720-X',
				unit: '台',
				number: 1,
				price: '5000'
			},
			{
				index: 7,
				name: '无源音箱',
				type: 'XL-206',
				unit: '个',
				number: 1,
				price: '1500'
			},
			{
				index: 8,
				name: '无线话筒',
				type: 'EAP-1150',
				unit: '套',
				number: 1,
				price: '3000'
			},
			{
				index: 9,
				name: '物联控制系统',
				type: '含红外转发器、智能开关、窗帘电机、导轨、传感器等',
				unit: '套',
				number: 1,
				price: '58000'
			}
		];

		function changeNav(index) {
			navIndex.value = index;
		}

		function handlePlanButtonClick() {
			dialogTableVisible.value = true;
		}

		return {
			gridData,
			dialogTableVisible,
			classroomDetail,
			planNavList,
			navIndex,
			changeNav,
			planLevelImgList,
			handlePlanButtonClick
		};
	}
});

</script>
<style lang='scss' scoped>
.classroom-des {

	.classroom-des-img {
		background: no-repeat center center;
		background-size: cover;
		height: 580px;
		top: -60px;
	}

	.classroom-content {
		margin: 115px auto;

		.classroom-content-detail {
			width: 497px;
			color: #FFFFFF;
		}
	}
}

.content-one {
	.classroom-item {
		margin: 10px auto auto;
		height: 575px;

		.classroom-item-box {
			width: 360px;
			height: 416px;

			p:nth-child(3) {
				width: 260px;
				margin: 0 auto 0;
			}

			p:nth-child(4) {
				width: 320px;
				margin: 16px auto 0;
			}
		}
	}
}

.plan-framework {
	height: 670px;
	background-color: #F5F5F7;

	.plan-content {
		margin: 80px auto auto;

		.plan-small-title {
			.framework-title {
				height: 32px;
			}
		}

		.plan-show img {
			height: 322px;
		}
	}
}

.plan-implementation {
	height: 1078px;

	.plan-content {
		margin: auto;
	}

	.plan-small-title {
		width: 348px;
		margin: 40px auto auto;

		.selected {
			background-color: $blue;
			color: $bWhite;
			transition: 1s;
		}
	}

	.framework-title {
		background: #FFFFFF;
		border: 1px solid rgba(26, 29, 52, 0.15);
		height: 32px;
		line-height: 32px;
		border-radius: 4px;
	}

	.plan-img {
		height: 727px;
		margin-top: 67px;

		img {
			display: inline-block;
			height: 727px;
		}
	}
}

.plan-scene {
	.plan-content {
		margin: 80px auto auto;
	}

	.application-scene {
		.application-title {
			text-align: left;
			text-indent: 40px;
		}

		.application-box {
			margin-top: 30px;
			text-align: left;

			.application-item {
				width: 230px;
			}

			img {
				display: inline-block;
				height: 370px;
				width: 500px;
				margin-top: 48px;
			}
		}
	}

	.record-video {
		margin-top: 70px;
		text-indent: 40px;
	}

	.record-content {
		background-image: url('~@/assets/record_video.png');
		background-repeat: no-repeat;
		background-size: cover;
		width: 1200px;
		height: 540px;
		margin-top: 30px;
		box-sizing: border-bocol-b-titlex;

		div {
			margin-top: 36px;
		}

		div:nth-child(2) {
			width: 357px;

		}

		div:nth-child(3) {
			width: 395px;
		}

		div p:nth-child(3) {
			font-weight: 400;
		}
	}

	.teach {
		margin-top: 77px;

		img {
			width: 54px;
			height: 54px;
			margin-left: 33px;
		}
	}

	.teach-item {
		.teach-ai {
			margin-top: 98px;
		}
	}
}

.data-plat {
	background-size: cover;
	background-image: url('~@/assets/data.png');
	height: 638px;
	background-position: center;
	position: relative;

	img {
		padding-left: 33px;
	}

	.data-img img {
		width: 1418px;
		height: 472px;
	}

	.plan-button {
		position: absolute;
		left: 50%;
		bottom: 60px;
		transform: translate(-50%);
		cursor: pointer;
		color: red;
		font-size: 20px;
		font-weight: 600;
	}
}

.width-item {
	width: 320px;
}

.remarks {
	margin-top: 35px;
}
</style>


