
import classroomUpgrade from '@/views/plan/components/classroomUpgrade.vue';
import lessonBuild from '@/views/plan/components/lessonBuild.vue';
import roomBuild from '@/views/plan/components/roomBuild.vue';
import trainingSystem from '@/views/plan/components/trainingSystem.vue';
import mixedClassroomProgram from '@/views/plan/components/mixedClassroomProgram.vue';
import { ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import {
	PLAN_CATEGORY_ROOM_BUILD,
	PLAN_CATEGORY_THREE_LESSON_BUILD,
	PLAN_CATEGORY_ROOM_UPGRADE,
	PLAN_CATEGORY_ROOM_SYSTEM,
	PLAN_CATEGORY_ROOM_SOLUTION
} from '@/utils/plan';
interface CategoryRoot {
	[key: string]: string;
}
const componentCategoryPair = {
	[PLAN_CATEGORY_ROOM_BUILD]: 'roomBuild',
	[PLAN_CATEGORY_THREE_LESSON_BUILD]: 'lessonBuild',
	[PLAN_CATEGORY_ROOM_UPGRADE]: 'classroomUpgrade',
	[PLAN_CATEGORY_ROOM_SYSTEM]: 'trainingSystem',
	[PLAN_CATEGORY_ROOM_SOLUTION]: 'mixedClassroomProgram'
} as CategoryRoot;
export default {
	components: {
		classroomUpgrade,
		lessonBuild,
		roomBuild,
		trainingSystem,
		mixedClassroomProgram
	},
	setup() {
		const route = useRoute();
		const currentComponent = ref('');
		// 默认显示“智慧教室终端”
		currentComponent.value = componentCategoryPair[route.params?.key as string];
		//监听路由变化
		onBeforeRouteUpdate(to => {
			currentComponent.value = componentCategoryPair[to.params?.key as string];
		});

		return {
			currentComponent
		};
	}
};
