<template>
	<section class="w-100p overflow-hidden training-system relative">
		<div class='absolute w-100p h-100p training-system-img'
			 v-lazy-bg:img='[require("@/assets/blur/traningSystem_bg.jpg"),true,require("@/assets/plan/trainingSystem_bg.png")]'></div>
		<div class="w-1200 lesson-build-title relative">
			<div class="f-s-32 col-b-white m-t-110 l-h-49">
				<p>内核是智慧实训推车，</p>
				<p>也是可移动的时光机</p>
			</div>
			<div class="f-s-16 col-b-white m-t-27 l-h-26 opacity-7 w-460 text-justify">
				不挑实训场景，无论教学、练习、考试、比赛、管理，应用智慧实训方案，完美解决实训围观、视角单一、直播不能回退、过程无法记录、成果检验低效、实训场景受限、实训教研局限等问题，快速升级实训体验。
			</div>
		</div>
	</section>
	<section class="text-center h-557 col-b-title white-bg content-one m-b-80">
		<div class="p-t-80 f-s-48 content-title  f-w-600">方案优势</div>
		<div class="flex-row w-1200 text-center block-center  justify-between  classroom-item">
			<div class="text-center classroom-item-box  m-t-70">
				<img src="@/assets/plan/plan_icon1.png" alt="" class="w-70 h-70" />
				<p class="f-s-20 m-t-20 f-w-500">让时光倒流</p>
				<p class="f-s-20 m-t-10 f-w-500">可随时重返任意时刻观看</p>
				<p class="f-s-16 m-t-16 opacity-7 l-h-26">
					60°全向捕捉示范操作细节，可随时拖动进度条到任意时间点回放，让学生看得清、看得全、学得会
				</p>
			</div>
			<div class="text-center classroom-item-box  m-t-70">
				<img src="@/assets/plan/plan_icon2.png" alt="" class="w-70 h-70" />
				<p class="f-s-20 m-t-20 f-w-500">全场景覆盖</p>
				<p class="f-s-20 m-t-10 f-w-500">教学、练习、考试、比赛、管理</p>
				<p class="f-s-16 m-t-16 opacity-7 l-h-26">
					适用于百种专业实训教学、大规模网络直播课、校企远程互动实践教学、网络实训教研、实训考核、实训赛事直播等
				</p>
			</div>
			<div class="text-center classroom-item-box  m-t-70">
				<img src="@/assets/plan/plan_icon3.png" alt="" class="w-70 h-70" />
				<p class="f-s-20 m-t-20 f-w-500">高度稳定</p>
				<p class="f-s-20 m-t-10 f-w-500">常态化实训教学更流畅</p>
				<p class="f-s-16 m-t-16 opacity-7 l-h-26">
					一体化嵌入式设计稳定可靠，大大减少多设备集成方案的故障几率。超便携可移动推车设计，实训教学操作效率提升200%
				</p>
			</div>
		</div>
	</section>
	<section class="plan_framework m-b-80">
		<div class="plan_content w-1200  block-center text-center col-b-title">
			<div class="plan_title f-w-600 f-s-48 f-w-600 m-t-80">方案架构</div>
			<div class="plan_small_title  col-b-blue f-w-500 f-s-22 m-t-10">云+网+端</div>
			<div class="m-t-60 room_show">
				<inter-image-show
					:img-width="1200"
					:img-height="340"
					:img-url="require('@/assets/plan/plan_framework.png')"
				></inter-image-show>
			</div>
		</div>
	</section>
	<!--  方案实施-->
	<section class="plan-implementation white-bg">
		<div class="plan-content w-1200 text-center col-b-title">
			<div class="plan-title  f-s-48 p-t-80 f-w-600">方案实施</div>
			<div class="plan-small-title col-b-blue f-w-500 f-s-22 m-t-10 flex-space-b m-t-40">
				<div
					class="framework-title l-h-32 w-100 text-center f-s-14 col-b-title pointer"
					v-for="(item, index) in planNavList"
					:key="item.name"
					@click="navIndex = index"
					:class="navIndex === index ? 'selected' : ''"
				>
					{{ item.name }}
				</div>
			</div>
			<div class="plan-img">
				<img :src="planNavList[navIndex].imgUrl" alt="" />
			</div>
		</div>
	</section>
	<!--应用场景-->
	<section class="plan_scene p-b-80">
		<div class="plan_content w-1200 block-center  text-center col-b-title">
			<div class="plan_title  f-w-600 f-s-42 f-w-600 m-t-80">应用场景</div>
			<div class="f-s-22 col-b-blue f-w-500 m-t-10">教学、练习、考试、比赛、管理</div>
			<div class="m-t-70">
				<div v-for="(item, index) in sceneList" :key="index" class="m-b-40">
					<div class="flex-space-b col-two" v-if="item.type === 2" :style="{ height: item.height }">
						<div
							class="scene-item white-bg w-580 brs30 p-40 h-100p relative flex-1"
							v-for="(v, i) in item.itemList"
							:key="v.title + i"
						>
							<p class="f-s-32 f-w-500 scene-title">{{ v.title }}</p>
							<div v-for="desc in v.descList" :key="desc" class="l-h-26">
								<description-with-circle
									:description="desc"
									:is-show-spot='v.descList.length >1'
								></description-with-circle>
							</div>
							<inter-image-show
								:img-width="500"
								:img-height="v.imgHeight"
								:img-url="v.url"
								class="absolute b-40"
							></inter-image-show>
						</div>
					</div>
					<div class="flex-row justify-between white-bg p-40 brs30" v-if="item.type === 1">
						<div class="scene-item m-r-90">
							<p class="f-s-32 f-w-500 scene-title">{{ item.title }}</p>
							<div v-for="(v, i) in item.descList" :key="i" class="l-h-26">
								<description-with-circle
									:description="v"
								></description-with-circle>
							</div>
						</div>
						<inter-image-show :img-width="560" :img-height="item.imgHeight" :img-url="item.url"></inter-image-show>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { defineComponent, ref } from 'vue';
import interImageShow from '@/components/interImageShow';
import DescriptionWithCircle from '@/views/production/components/DescriptionWithCircle.vue';
export default defineComponent({
	name: 'trainingSystem',
	components: {
		interImageShow,
		DescriptionWithCircle
	},
	setup() {
		let navIndex = ref(1);
		const planNavList = [
			{ name: '基础版', imgUrl: require('@/assets/plan/plan_implementation1.png') },
			{ name: '扩展版', imgUrl: require('@/assets/plan/plan_implementation2.png') }
		];
		//应用场景数据
		const sceneList = [
			{
				type: 2,
				height: '595px',
				itemList: [
					{
						title: '适用于百种专业实训教学',
						descList: [
							'无线移动实训推车自带电池、滚轮，适应室内、室外各种场景，覆盖园林设计、汽修、医护、烹饪、服装设计、数控、美容美发等百种专业实训教学。'
						],
						url: require('@/assets/plan/application_scenario1.png'),
						imgHeight: 320
					},
					{
						title: '支持大规模网络直播课',
						descList: [
							'配合实训资源平台，实现万人级别高清线上直播课，支持多终端一键扫码观看，开启名校、名师网络实训课堂。'
						],
						url: require('@/assets/plan/application_scenario2.png'),
						imgHeight: 320
					}
				]
			},
			{
				type: 1,
				title: '常态化校企远程互动实践教学',
				url: require('@/assets/plan/application_scenario3.png'),
				imgHeight: 300,
				descList: [
					'支持多方远程音视频互动，实时连接教室与校企合作单位，让学生“足不出校”就可以参与企业实操教学；',
					'内置4A音频处理器，解决音视频交互回声、啸叫等问题，适用于任何实训环境。'
				]
			},
			{
				type: 1,
				title: 'AI督导巡课',
				url: require('@/assets/plan/application_scenario4.png'),
				imgHeight: 328,
				descList: [
					'搭配AI分析摄像机，实现线上听评课，还能同步查看课堂教情、学情分析报告，助力精准评课；',
					'课中、课后任意评课，多画面评课（示范特写、示范全景、课件、学生实操等），掌握课堂细节，完整生成听评报告；',
					'线上实时巡课、实录巡课，自动抓拍实证画面，可实现对所有实训场景集中管控，提高实训教学管理实效。'
				]
			},
			{
				type: 2,
				height: '572px',
				itemList: [
					{
						title: '实训课程一键录制',
						descList: [
							'一键开启全自动录制，精准捕捉老师全景、老师特写、学生全景、教学大屏画面，生成精品课，助力建设校本资源库。'
						],
						url: require('@/assets/plan/application_scenario5.png'),
						imgHeight: 323
					},
					{
						title: '网络实训教研',
						descList: [
							'支持千人以上大规模线上教研活动，教研过程、成果、评价等全程记录，助力教师专业成长，提升实训教学质量。'
						],
						url: require('@/assets/plan/application_scenario6.png'),
						imgHeight: 328
					}
				]
			},
			{
				type: 2,
				height: '558px',
				itemList: [
					{
						title: '实训考核信息化',
						descList: [
							'多种考生认证机制，完整记录考核讨程，为老师评审提供依据；',
							'自定义量规表，让实训考核过程标准化、规范化。'
						],
						url: require('@/assets/plan/application_scenario7.png'),
						imgHeight: 285
					},
					{
						title: '赛事直播、留档',
						descList: ['支持全自动导播，可进行选手特写和赛事全貌多机位赛事直播，赛事全程录像留档。'],
						url: require('@/assets/plan/application_scenario8.png'),
						imgHeight: 285
					}
				]
			}
		];
		return { planNavList, navIndex, sceneList };
	}
});
</script>
<style lang="scss" scoped>
.training-system {
	height: 460px;
	.training-system-img {
		background: no-repeat center center;
		height: 580px;
		top: -60px;
	}
	.lesson-build-title {
		margin: 170px auto auto;
		.w-460{
			width: 460px;
		}
	}
}
.h-557{
	height: 557px;
}
.plan_scene {
	.scene-item {
		text-align: left;
		box-sizing: border-box;
	}
	.col-two > div:first-child {
		margin-right: 40px;
	}
	.scene-title {
		margin-bottom: 32px;
	}
}
.plan-implementation {
	height: 1078px;

	.plan-content {
		margin: auto;
	}

	.plan-small-title {
		width: 224px;
		margin: 40px auto auto;

		.selected {
			background-color: $blue;
			color: $bWhite;
			transition: 1s;
		}
	}

	.framework-title {
		background: #ffffff;
		border: 1px solid rgba(26, 29, 52, 0.15);
		height: 32px;
		line-height: 32px;
		border-radius: 4px;
	}

	.plan-img {
		width: 1200px;
		height: 676px;
		margin-top: 67px;

		img {
			display: inline-block;
			height: 676px;
		}
	}
}
.classroom-item-box {
	width: 360px;
	p:nth-child(3) {
		width: 320px;
		margin: 16px auto 0;
	}
}
.room_show img {
	height: 340px;
}
.plan_show img {
	width: 1200px;
	height: 460px;
}
.application_box img {
	height: 370px;
}
.news img {
	height: 356px;
}
</style>
