<template>
	<section class="w-100p overflow-hidden training-system relative">
		<div class='absolute w-100p h-100p training-system-img'
			 v-lazy-bg:img='[require("@/assets/blur/solution_bg.jpg"),true,require("@/assets/plan/solution_bg.png")]'></div>
		<div class="w-1200 lesson-build-title relative">
			<div class="f-s-32 col-b-white m-t-110 l-h-49">
				<p>1台设备+4根线缆</p>
				<p>5分钟让K12普通教室升级为智慧教室</p>
			</div>
			<div class="f-s-16 col-b-white m-t-27 l-h-26 opacity-7 w-500 text-justify">
				以混合式教学终端为核心，快速将普通教室升级成支持混合式线上线下授课、常态化录播、听评课教研、远程巡课督导、网络教研、三个课堂、无线麦扩声等多种教学应用的智慧型教室，为学校带来“教、学、管、研、评”全方位教学体验提升。
			</div>
		</div>
	</section>
	<section class="text-center h-557 col-b-title white-bg content-one m-b-80">
		<div class="p-t-80 f-s-48 content-title  f-w-600">方案优势</div>
		<div class="flex-row w-1200 text-center block-center justify-between  classroom-item">
			<div class="text-center classroom-item-box  m-t-70">
				<img src="@/assets/plan/solution_icon1.png" alt="" class="w-70 h-70" />
				<p class="f-s-20 m-t-20 f-w-500">高度集成</p>
				<p class="f-s-20 m-t-10 f-w-500">超级9合1，一机多能</p>
				<p class="f-s-16 m-t-16 opacity-7 l-h-26">
					一体集成录播、360°全向多媒体音箱、8麦克风阵列、4A音频处理器、音频功放、无线麦、POE
					交换机、音视频采集卡、USB声卡
				</p>
			</div>
			<div class="text-center classroom-item-box  m-t-70">
				<img src="@/assets/plan/solution_icon2.png" alt="" class="w-70 h-70" />
				<p class="f-s-20 m-t-20 f-w-500">部署简单</p>
				<p class="f-s-20 m-t-10 f-w-500">5分钟完成一间教室调试</p>
				<p class="f-s-16 m-t-16 opacity-7 l-h-26">
					只需1台核心设备，连接4根线缆，5 分钟让K12普通教室升级为智慧教室
				</p>
			</div>
			<div class="text-center classroom-item-box  m-t-70">
				<img src="@/assets/plan/solution_icon3.png" alt="" class="w-70 h-70" />
				<p class="f-s-20 m-t-20 f-w-500">多场景应用</p>
				<p class="f-s-20 m-t-10 f-w-500">多种教学模式1间教室就够了</p>
				<p class="f-s-16 m-t-16 opacity-7 l-h-26">
					支持线上直播教学、OMO 混合式教学、跨校区远程互动教学、国内国际学术交流、三个课堂等多种教学模式
				</p>
			</div>
		</div>
	</section>
	<section class="plan_framework m-b-80">
		<div class="plan_content w-1200  block-center text-center col-b-title">
			<div class="plan_title f-w-600 f-s-48 f-w-600 m-t-80">方案架构</div>
			<div class="plan_small_title  col-b-blue f-w-500 f-s-22 m-t-10">云+网+端</div>
			<div class="m-t-60 room_show">
				<inter-image-show
					:img-width="1200"
					:img-height="340"
					:img-url="require('@/assets/plan/solution_framework.png')"
				></inter-image-show>
			</div>
		</div>
	</section>
	<!--  方案实施-->
	<section class="plan-implementation white-bg">
		<div class="plan-content w-1200 text-center col-b-title">
			<div class="plan-title f-s-48 p-t-80 f-w-600">方案实施</div>
			<div class="plan-small-title col-b-blue f-w-500 f-s-22 m-t-10 flex-space-b m-t-40">
				<div
					class="framework-title l-h-32 w-100 text-center f-s-14 col-b-title pointer"
					v-for="(item, index) in planNavList"
					:key="item.name"
					@click="navIndex = index"
					:class="navIndex === index ? 'selected' : ''"
				>
					{{ item.name }}
				</div>
			</div>
			<div class="plan-img">
				<img :src="planNavList[navIndex].imgUrl" alt="" />
			</div>
		</div>
	</section>
	<!--应用场景-->
	<section class="plan_scene p-b-120">
		<div class="plan_content w-1200 block-center  text-center col-b-title">
			<div class="plan_title  f-w-600 f-s-42 f-w-600 m-t-80">应用场景</div>
			<div class="f-s-22 col-b-blue f-w-500 m-t-10">智慧教学全场景覆盖，轻松实现“教、学、管、研、评”</div>
			<div class="m-t-70">
				<div class="flex-row justify-between white-bg p-40 brs30 m-b-40">
					<div class="scene-item m-r-90">
						<p class="f-s-32 f-w-500 scene-title">听评课 + 微格教室</p>
						<div v-for="desc in descList[0]" :key="desc" class="l-h-26 text-justify">
							<description-with-circle :description="desc"></description-with-circle>
						</div>
					</div>
					<inter-image-show
						:img-width="530"
						:img-height="337"
						:img-url="require('@/assets/plan/solution_scene1.png')"
					></inter-image-show>
				</div>

				<div class="flex-space-b col-two m-b-40">
					<div class="scene-item white-bg w-580 brs30 p-40 h-100p relative flex-1" style="height:702px">
						<p class="f-s-32 f-w-500 scene-title">常态化录播</p>
						<div v-for="desc in descList[1]" :key="desc" class="l-h-26">
							<description-with-circle :description="desc"></description-with-circle>
						</div>
						<inter-image-show
							:img-width="500"
							:img-height="377"
							:img-url="require('@/assets/plan/solution_scene2.png')"
							class="m-t-40"
						></inter-image-show>
					</div>
					<div class="scene-item w-580 brs30 h-100p relative flex-1">
						<div class="white-bg p-40 brs30 h-702">
							<p class="f-s-32 f-w-500 scene-title">OMO混合式教学</p>
							<p class="f-s-16 f-w-400 l-h-26 opacity-7 m-b-24 desc text-justify">
								深度融合线上和线下教学场景，满足后疫情时代越来越普及的混合式教学需求。
							</p>
							<inter-image-show
								:img-width="500"
								:img-height="230"
								:img-url="require('@/assets/plan/solution_scene3.png')"
							></inter-image-show>
						</div>
						<div class="white-bg p-40 brs30 m-t-40">
							<p class="f-s-32 f-w-500 scene-title">三个课堂</p>
							<p class="f-s-16 f-w-400 l-h-26 opacity-7 m-b-24 desc text-justify">
								快速实现区域级教育战略布局，促进教育公平、提升教育质量。
							</p>
						</div>
					</div>
				</div>

				<div class="flex-row justify-between white-bg p-40 brs30 m-b-40">
					<div class="scene-item m-r-90">
						<p class="f-s-32 f-w-500 scene-title">督导巡课</p>
						<p class="opacity-7 m-b-24 desc text-justify">
							快速实现区域级教育战略布局，促进教育公平、提升教育质量。
						</p>
					</div>
					<inter-image-show
						:img-width="726"
						:img-height="450"
						:img-url="require('@/assets/plan/solution_scene4.png')"
					></inter-image-show>
				</div>

				<div class="flex-row justify-between white-bg p-40 brs30">
					<div class="scene-item m-r-90">
						<p class="f-s-32 f-w-500 scene-title">区域网络教研</p>
						<p class="opacity-7 m-b-24 desc">
							支持大规模线上教研活动，形成覆盖教育全流程、全领域的教研网络体系。
						</p>
						<p class="f-s-32 f-w-500 scene-title">云端家长会</p>
						<p class="opacity-7 m-b-24 desc">
							创设线上家长会情景，支持线上签到、视频播放、共享屏幕、视频互动、语音互动等。
						</p>
					</div>
					<inter-image-show
						:img-width="726"
						:img-height="400"
						:img-url="require('@/assets/plan/solution_scene5.png')"
					></inter-image-show>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { defineComponent, ref } from 'vue';
import interImageShow from '@/components/interImageShow';
import DescriptionWithCircle from '@/views/production/components/DescriptionWithCircle.vue';
export default defineComponent({
	name: 'mixedClassroomProgram',
	components: {
		interImageShow,
		DescriptionWithCircle
	},
	setup() {
		let navIndex = ref(1);
		const planNavList = [
			{ name: '基础版', imgUrl: require('@/assets/plan/solution_implementation1.png') },
			{ name: '扩展版', imgUrl: require('@/assets/plan/solution_implementation2.png') }
		];
		const descList = [
			[
				'支持快捷短语、教学行为（需配置AI摄像机）、老师语音（需搭配课堂语音文本转换模块）等多种人工、自动微格打点，让教学评课更便捷;',
				'可根据不同的学段、学科灵活设置评课量表;',
				'课中、课后任意评课，快速生成课程听评报告，呈现优秀教师风采。'
			],
			[
				'支持4画面（老师特写+老师全景+学生全景+老师电脑）常态化自动录播；',
				'配合资源管理平台，支持线上直播、随堂录制，帮助学校建设优质校本资源库，实现优质教学资源快速共建共享。'
			]
		];
		return { planNavList, navIndex, descList };
	}
});
</script>
<style lang="scss" scoped>
.training-system {
	height: 460px;
	.training-system-img {
		background: no-repeat center center;
		height: 580px;
		top: -60px;
	}
	.lesson-build-title {
		margin: 141px auto auto;
	}
}
.h-557{
	height: 557px;
}
.plan_scene {
	.scene-item {
		text-align: left;
		box-sizing: border-box;
	}
	.col-two > div:first-child {
		margin-right: 40px;
	}
	.scene-title {
		margin-bottom: 32px;
	}
	.desc {
		line-height: 26px;
	}
	.desc-list:before {
		content: '';
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: rgba(36, 37, 51, 0.7);
		margin-right: 16px;
	}
}
.plan-implementation {
	height: 1078px;

	.plan-content {
		margin: auto;
	}

	.plan-small-title {
		width: 224px;
		margin: 40px auto auto;

		.selected {
			background-color: $blue;
			color: $bWhite;
			transition: 1s;
		}
	}

	.framework-title {
		background: #ffffff;
		border: 1px solid rgba(26, 29, 52, 0.15);
		height: 32px;
		line-height: 32px;
		border-radius: 4px;
	}

	.plan-img {
		height: 727px;
		margin-top: 67px;

		img {
			display: inline-block;
			height: 727px;
		}
	}
}
.classroom-item-box {
	width: 360px;
	p:nth-child(3) {
		width: 320px;
		margin: 16px auto 0;
	}
}
.room_show img {
	height: 340px;
}
.plan_show img {
	width: 1200px;
	height: 460px;
}
.application_box img {
	height: 370px;
}
.news img {
	height: 356px;
}
</style>
