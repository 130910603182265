<template>
	<section class='h-460 w-100p overflow-hidden  lesson-build relative'>
		<div class='absolute w-100p h-100p lesson-build-img'
			 v-lazy-bg:img='[require("@/assets/blur/class_title_bg.jpg"),true,require("@/assets/plan/class_title_bg.png")]'></div>
		<div class='w-1200 lesson-build-title relative'>
			<div class='f-s-32 col-b-white' style='width: 530px;'>专递课堂·名师课堂·名校网络课堂
				快速实现区域级教育战略布局
			</div>
			<div class='f-s-16 col-b-white m-t-27 text-justify'>“三个课堂”解决方案，可实现1个校区上课，多个校区实时互动的1+N
				多点模式，实现专递课堂、听评课教研、优质教学资源的共享共建。支持多校协作教学，使更多学生共享名师课堂、名校网络课堂，落实常态化应用。
			</div>
		</div>
	</section>

	<section class='w-100p white-bg p-b-80'>
		<div class='w-1200 block-center  '>
			<div class='courier-class'>
				<div class='f-s-42 col-b-title f-w-600 p-l-40'>专递课堂解决方案</div>
				<div class='l-h-32 f-s-20 opacity-7 m-t-8 p-l-40'>专递课堂解决方案以“1 + N + 1”( 1个主讲教室、N个听讲教室、1个融合管理平台
					)实现薄弱校网上专门开课、同步上课，支持互动教学，促进教育公平和均衡发展。
				</div>
				<div class='m-t-60'>
					<inter-image-show :img-width='1200' :img-height='444'
									  :img-url="require('@/assets/plan/courier_class.png')"
									  style='padding-top: 0'></inter-image-show>
				</div>
			</div>
			<div class='col-b-blue f-s-32 m-t-70 f-w-500  p-l-40'>方案特色</div>
			<div class='flex-row flex-space-b m-t-30'>
				<div class='text-center room-item room-plan'>
					<div class='m-t-70'>
						<img src='@/assets/feature_stable@2x.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500'>
						<p>高度集成</p>
						<p>稳定可靠，易维护</p>
					</div>
					<div class='opacity-7 m-t-16'>主讲，听讲教室分别由1台核心设备建设，嵌入式设计稳定可靠，易于安装及维护</div>
				</div>
				<div class='text-center room-item room-plan'>
					<div class='m-t-70'>
						<img src='@/assets/feature_easy@2x.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500'>
						<p>高度易学</p>
						<p>1分钟学会，易推广</p>
					</div>
					<div class='opacity-7'>无论是主讲还是听讲教室，1台核心设备均平移了手机的使用习惯，老师可快熟上手</div>
				</div>
				<div class='text-center room-item room-plan'>
					<div class='m-t-70'>
						<img src='@/assets/plan/tv.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500'>
						<div class='f-s-20 f-w-500'>
							<p>高度兼容</p>
							<p>按需建设，类型多样</p>
						</div>
					</div>
					<div class='opacity-7 m-t-16'>主讲教室可升级为适应未来发展需要的智慧教室，避免重复建设、浪费资金</div>
				</div>
				<div class='text-center room-item room-plan'>
					<div class='m-t-70'>
						<img src='@/assets/plan/video.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500'>
						<div class='f-s-20 f-w-500 w-200'>
							<p>高性价比</p>
							<p>功能强大，常态化应用</p>
						</div>
					</div>
					<div class='opacity-7 m-t-16'>听讲教室具有远程互动、录播、直播、扩声等功能，支持远程巡课、听评课、协同教研</div>
				</div>
			</div>
			<div class='col-b-blue f-s-32 m-t-70 f-w-500  p-l-40 '>应用场景</div>
			<div class='application plan-characteristic bg-light-grey m-t-30 brs30 p-b-80 '>
				<div class='w-1200 block-center border-box p-40 '>
					<div class='f-w-600 f-s-20 col-b-title'>1校带多校的同步课堂</div>
				</div>
			</div>
		</div>

	</section>

	<section class='teach-plan bg-light-grey '>
		<div class='w-1200 block-center'>
			<div class='courier-class'>
				<div class='f-s-42 col-b-title f-w-600 p-l-40'>名师课堂解决方案</div>
				<div class='l-h-32 f-s-20 opacity-7 m-t-8 p-l-40'>
					名师课堂方案，通过智慧教室终端建设教研室，结合融合平台的督导教学平台，组建网络研修共同体，开展名师示范课、网络协同教研，发挥名师示范效应、促进教师专业发展。
				</div>
				<div class='m-t-60'>
					<inter-image-show :img-width='1200' :img-height='280'
									  :img-url="require('@/assets/plan/teach_class.png')"
									  style='padding-top: 0'></inter-image-show>
				</div>
			</div>
			<div class='col-b-blue f-s-32 m-t-70 f-w-500  p-l-40 '>方案特色</div>
			<div class='flex-row flex-space-a m-t-30'>
				<div class='text-center room-item plan-list'>
					<div class='m-t-70'>
						<img src='@/assets/plan/build.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500 ' style='width: 320px;'>建设区域一体化、多级联动教研系统</div>
					<div class='opacity-7 m-t-16 block-center build-lesson' style='width: 320px;'>
						支持500+方互动，支持多终端加入，以拨号形式实现互动
					</div>
				</div>
				<div class='text-center room-item plan-list'>
					<div class='m-t-70'>
						<img src='@/assets/plan/share.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500' style='width: 360px;'>资源共建共享，实现教研信息化、智能化</div>
					<div class='opacity-7 m-t-30 block-center build-item' style='width: 320px;'>支持督导听课，数据统计，辅助教研活动决策
					</div>
				</div>
			</div>
			<div class='col-b-blue f-s-32 m-t-70 f-w-500  p-l-40'>应用场景</div>
			<div class='flex-row flex-space-b m-b-80 '>
				<div class='white-bg brs30 m-t-30 p-b-50'>
					<div class='border-box p-40'>
						<div class='f-s-20 f-w-500'>网络互动教研</div>
						<div class='opacity-7 m-t-16 w-300'>支持教研活动创建、预约、通知、管理、文字讨论、量规表打分、统计</div>
					</div>
					<div class='teach-characteristic'>
						<inter-image-show :img-width='380' :img-height='370'
										  :img-url="require('@/assets/plan/interaction.png')"></inter-image-show>
					</div>
				</div>
				<div class='white-bg brs30 m-t-30 p-b-50'>
					<div class='border-box p-40'>
						<div class='f-s-20 f-w-500'>教研分享</div>
						<div class='opacity-7 m-t-16 w-300'>教研内容协同共享、课件资源发布、教研视频分享</div>
					</div>
					<div class='teach-characteristic'>
						<inter-image-show :img-width='380' :img-height='370'
										  :img-url="require('@/assets/plan/teach_share.png')"></inter-image-show>
					</div>
				</div>
				<div class='white-bg brs30 m-t-30 p-b-50'>
					<div class='border-box p-40'>
						<div class='f-s-20 f-w-500'>听评课</div>
						<div class='opacity-7 m-t-16 w-300'>远程视频督导、评课、支持手机端听评课</div>
					</div>
					<div class='teach-characteristic m-t-18'>
						<inter-image-show :img-width='380' :img-height='370'
										  :img-url="require('@/assets/plan/listen_lesson.png')"></inter-image-show>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class='w-100p white-bg'>
		<div class='w-1200 block-center  '>
			<div class='courier-class'>
				<div class='f-s-42 col-b-title f-w-600 '>名校网络课堂解决方案</div>
				<div class='l-h-32 f-s-20 opacity-7 m-t-8 '>
					名校网络课堂方案，以融合平台资源管理平台为核心，针对优质学校，通过建设网络学校、网络课程等形式，推动优质教育资源在区域内共享。
				</div>
				<div class='m-t-60'>
					<inter-image-show :img-width='1200' :img-height='318'
									  :img-url="require('@/assets/plan/school_net.png')"
									  style='padding-top: 0'></inter-image-show>
				</div>
			</div>
			<div class='col-b-blue f-s-32 m-t-70 f-w-500  p-l-40'>方案特色</div>
			<div class='flex-row flex-space-b m-t-30'>
				<div class='text-center room-item class-plan'>
					<div class='m-t-70'>
						<img src='@/assets/plan/camera.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500'>全自动精品录播</div>
					<div class='opacity-7 m-t-16 l-h-26 m-l-40 m-r-15 plan-item'>精品课程全自动录制，3D传感器让自动录播媲美人工拍摄</div>
				</div>
				<div class='text-center room-item class-plan'>
					<div class='m-t-70'>
						<img src='@/assets/plan/television.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500'>超大规模直播</div>
					<div class='opacity-7 m-t-16 l-h-26 m-l-40 m-r-15 plan-item'>可直接与第三方直播CDN对接，进行超过10万人的大规模直播活动</div>
				</div>
				<div class='text-center room-item class-plan'>
					<div class='m-t-70'>
						<img src='@/assets/plan/video_camera.png' alt='' class='w-70 h-70'>
					</div>
					<div class='f-s-20 f-w-500'>支持视频会议</div>
					<div class='opacity-7 m-t-16 l-h-26 m-l-40 m-r-15 plan-item'>可对接第三方视频会议MCU和视频会议终端，组织行政视频会议</div>
				</div>
			</div>
			<div class='col-b-blue f-s-32 m-t-70 f-w-500  p-l-40'>应用场景</div>
			<div class='application teach-live m-t-30'>
				<div class='flex-row flex-space-b m-b-120'>
					<div class='bg-light-grey brs30 teach-item p-b-40'>
						<div class='border-box p-40'>
							<div class='f-s-20 f-w-500' style='width: 493px'>教学直播，支持直播内容审核，二维扫码即可观看，实现优质教学资源快速、广泛传播
							</div>
						</div>
						<div>
							<inter-image-show :img-width='540' :img-height='345'
											  :img-url="require('@/assets/plan/teach_live.png')"
											  class='m-l-40'></inter-image-show>
						</div>
					</div>
					<div class='bg-light-grey brs30 teach-item p-b-40'>
						<div class='border-box p-40'>
							<div class='f-s-20 f-w-500' style='width: 493px'>金课建设，通过建设精品课打造名校、名师、名课课堂，提升教学质量</div>
						</div>
						<div>
							<inter-image-show :img-width='540' :img-height='345'
											  :img-url="require('@/assets/plan/live_show.png')"
											  class='m-l-40'></inter-image-show>
						</div>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>
<script>
import { defineComponent } from 'vue';
import interImageShow from '@/components/interImageShow';

export default defineComponent({
	name: 'lessonBuild',
	components: {
		interImageShow
	}
});
</script>
<style lang='scss' scoped>
.lesson-build {
	height: 460px;

	.lesson-build-img {
		background: no-repeat center center;
		height: 580px;
		top: -60px;
	}

	.lesson-build-title {
		margin: 125px auto auto;

		div:nth-child(1) {
			width: 423px;
		}

		div:nth-child(2) {
			width: 530px;
		}
	}
}

.courier-class {
	div:nth-child(1) {
		padding-top: 80px;
	}

	div:nth-child(2) {
		width: 820px;
	}
}

.build-lesson {
	width: 320px;
	margin-top: 16px;
}

.plan-list {
	height: 340px;
}

.build-item {
	margin-top: 20px;
}

.room-plan {
	width: 285px;
}

.class-plan {
	width: 380px;
}

.teach-item {
	width: 580px;
}

.room-item {
	width: 400px;

	div:nth-child(2) {
		width: 180px;
		margin: 30px auto auto;
	}

	div:nth-child(3) {
		width: 250px;
		margin: 16px auto 70px;

	}
}

.plan-item {
	width: 320px;
}

.application img {
	height: 409px;
	margin-bottom: 80px;
}

.teach-img {
	width: 1200px;
	height: 280px;
}

.teach-net-img {
	height: 318px;
}

.plan-characteristic {
	background-image: url('~@/assets/plan/application.png');
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: right bottom;
	height: 409px;
}

.teach-characteristic img {
	height: 370px;
	width: 380px;
	margin-bottom: 50px;
}

.teach-live img {
	width: 540px;
	height: 345px;
}
</style>