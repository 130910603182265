<template>
	<section class='w-100p overflow-hidden room-build relative'>
		<div class='absolute w-100p h-100p room-build-img'
			 v-lazy-bg:img='[require("@/assets/blur/classroom_tradition.jpg"),true,require("@/assets/old_classroom_tradition@2x.png")]'></div>
		<div class='w-1200 lesson-build-title  relative'>
			<div class='f-s-32 col-b-white m-t-110'>1台核心设备=常态化智慧教室</div>
			<div class='f-s-16 col-b-white m-t-27 l-h-26 opacity-7 w-500 text-justify'>传统教室升级改造方案，通过1
				台“智慧教室终端”快速实现课堂应用升级，提供智能化中控、圈点批注、电子白板、对比教学、信息发布等功能，打造智慧教学、集中管理于一体的信息化教学系统。
			</div>
		</div>
	</section>
	<section class='text-center h-500 col-b-title white-bg content-one m-b-80'>
		<div class='p-t-80 f-s-48 content-title  f-w-600'>方案优势</div>
		<div class='flex-row w-1200 text-center block-center  justify-between  classroom-item'>
			<div class='text-center classroom-item-box  m-t-70'>
				<img src='@/assets/class_application.png' alt='' class='w-70 h-70'>
				<p class='f-s-20 m-t-20 f-w-500'>极致优化教学高频应用</p>
				<p class='f-s-16 m-t-16 opacity-7 l-h-26'>投影幕、LED屏大教室也能轻松实现圈点、白板应用，教学更灵活</p>
			</div>
			<div class='text-center classroom-item-box  m-t-70'>
				<img src='@/assets/feature_stable@2x.png' alt='' class='w-70 h-70'>
				<p class='f-s-20 m-t-20 f-w-500'>集成度高，兼容性强</p>
				<p class='f-s-16 m-t-16 opacity-7 l-h-26'>1台设备集成多媒体中控、无线麦、无线投屏等应用，支持设备利旧</p>
			</div>
			<div class='text-center classroom-item-box  m-t-70'>
				<img src='@/assets/feature_easy@2x.png' alt='' class='w-70 h-70'>
				<p class='f-s-20 m-t-20 f-w-500'>操作简单，易于推广</p>
				<p class='f-s-16 m-t-16 opacity-7 l-h-26'>教师1分钟可学会操作，易普及，并方便教师养成日常使用习惯</p>
			</div>
		</div>
	</section>
	<section class='plan_framework m-b-80'>
		<div class='plan_content w-1200  block-center text-center col-b-title'>
			<div class='plan_title f-w-600 f-s-48 f-w-600 m-t-80'>方案架构</div>
			<div class='plan_small_title  col-b-blue f-w-500 f-s-22 m-t-10'>云+网</div>
			<div class='m-t-60 room_show'>
				<inter-image-show :img-width='1200' :img-height='340'
								  :img-url="require('@/assets/plan/room_framework.png')"></inter-image-show>
				<!--        <img src="@/assets/plan/room_framework.png" alt="" >-->
			</div>
		</div>
	</section>
	<section class='plan_implementation white-bg p-b-80'>
		<div class='plan_content w-1200 block-center text-center col-b-title'>
			<div class='plan_title  f-s-42 p-t-80 f-w-600'>基础版升级方案</div>
			<div class='m-t-60 plan_show'>
				<inter-image-show :img-width='1200' :img-height='460'
								  :img-url="require('@/assets/plan/room_basics.png')"></inter-image-show>
				<!--        <img src="@/assets/plan/room_basics.png" alt="">-->
			</div>
			<div class='plan_title  f-s-42 p-t-80 f-w-600'>高级版升级方案</div>
			<div class='m-t-60 plan_show'>
				<inter-image-show :img-width='1200' :img-height='460'
								  :img-url="require('@/assets/plan/room_high.png')"></inter-image-show>
				<!--        <img src="@/assets/plan/room_high.png" alt="">-->
			</div>
		</div>
	</section>

	<section class='plan_scene p-b-120'>
		<div class='plan_content w-1200 block-center  text-center col-b-title'>
			<div class='plan_title  f-w-600 f-s-42 f-w-600 m-t-80'>主要应用场景</div>
			<div class='flex-space-b application-scene m-t-70'>
				<div class='text-left col-b-title'>
					<div class='application_title f-s-32 col-b-blue f-w-500 m-l-40'>智慧教学，操作简单</div>
					<div class='application_box border-box p-40 brs30 white-bg m-t-30 ' style='padding-bottom: 87px;'>
						<p class='m-t-0 f-s-20 f-w-500'>圈点批注</p>
						<p class='opacity-7 f-w-400  f-s-16 m-t-8 width-more-item'>
							支持本地电脑、外接笔记本、无线投屏等全信号源圈点批注，并可导出保存 </p>
						<p class='f-s-20 m-t-24 f-w-500'>电子白板</p>
						<p class='opacity-7 f-w-400 w-500 f-s-16 m-t-8 width-more-item'>
							LED屏、投影幕也可展示触控屏的白板书写，实现板书内容呈现</p>
						<p class='f-s-20 m-t-24 f-w-500'>对比教学 </p>
						<p class='opacity-7 f-w-400 w-500 f-s-16 m-t-8'>支持本地电脑、外接笔记本、无线投屏等内容对比教学</p>
						<div class='m-t-40'>
							<inter-image-show :img-width='500' :img-height='370'
											  :img-url="require('@/assets/application.jpg')"></inter-image-show>
							<!--              <img src="@/assets/application.jpg" alt="" class="w-500">-->
						</div>
					</div>
				</div>
				<div class='text-left'>
					<div class='application_title f-s-32 col-b-blue f-w-500 m-l-40'>智能中控，轻松运维</div>
					<div class='application_box border-box col-b-title p-40 brs30 white-bg m-t-30'>
						<div class=' flex-row'>
							<div class='application_item width-item'>
								<div class='application_item f-s-20 f-w-500'>账号同步</div>
								<div class='application_item f-w-400 l-h-24 m-t-8 f-s-16 opacity-7 width-small-item'>
									账号登录方式多样，自动链接个人空间
								</div>
							</div>
							<div class='application_item m-l-40'>
								<div class='application_item f-s-20 f-w-500'>多媒体中控</div>
								<div class='application_item f-w-400 l-h-24 m-t-8 f-s-16 opacity-7 width-item'>
									智能管控教室触控大屏、投影幕布、教师电脑等设备
								</div>
							</div>
						</div>
						<div class='flex-row m-t-30'>
							<div class='application_item'>
								<div class='application_item f-s-20 f-w-500'> 远程控制</div>
								<div class='application_item f-w-400 l-h-24 m-t-8  f-s-16 opacity-7 width-item'>
									远程批量管控教室教学设备和环境设备
								</div>
							</div>
							<div class='application_item m-l-40'>
								<div class='application_item f-s-20 f-w-500'>远程运维</div>
								<div class='application_item f-w-400 l-h-24 m-t-8 f-s-16 opacity-7 width-item'>
									终端自动故障告警，系统自动升级或更新，支持远程协助接管教师电脑
								</div>
							</div>
						</div>
						<div class='m-t-60'>
							<inter-image-show :img-width='500' :img-height='370'
											  :img-url="require('@/assets/application_class.png')"></inter-image-show>
							<!--              <img src="@/assets/application_class.png" alt="" class="w-500">-->
						</div>
						<div class='col-b-red f-s-12 m-t-30'>
							注：高级版支持物联网管控
						</div>
					</div>
				</div>
			</div>
			<div class='record_video f-s-32 col-b-blue text-left m-l-40 f-w-500 m-t-70'>校园信息，精准发布</div>
			<div class='m-t-30 brs30 white-bg text-center col-b-title f-s-20 f-w-500 news'>
				<div class='p-t-40'>一键发布，图片、文字、音视频等全媒体资源到任意多间教室</div>
				<div class='m-t-10'>支持在线直播，校园公告实时通</div>
				<div class='m-t-20'>
					<inter-image-show :img-width='850' :img-height='356'
									  :img-url="require('@/assets/plan/news.png')"></inter-image-show>
					<!--          <img src="@/assets/plan/news.png" alt="">-->
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { defineComponent } from 'vue';
import interImageShow from '@/components/interImageShow';

export default defineComponent({
	name: 'roomBuild',
	components: {
		interImageShow
	}
});
</script>
<style lang='scss' scoped>
.width-item {
	width: 220px;
}

.width-small-item {
	width: 195px;
}

.width-more-item {
	width: 447px;
}

.room-build {
	height: 460px;

	.room-build-img {
		background: no-repeat center center;
		background-size: cover;
		height: 580px;
		top: -60px;
	}

	.lesson-build-title {
		margin: 180px auto auto;
	}
}

.classroom-item-box {
	width: 360px;

	p:nth-child(3) {
		width: 320px;
		margin: 16px auto 0;
	}
}

.room_show img {
	height: 340px;
}

.plan_show img {
	width: 1200px;
	height: 460px;
}

.application_box img {
	height: 370px;
}

.news img {
	height: 356px;
}
</style>
